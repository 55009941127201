import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import { useToast } from '../components/ToastContext'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import { getUserSettings, setUserSettings } from '../api/settings'
import { getCalendarEvents } from '../api/calendar'
import { pushEventToTimesheets } from '../api/timesheets'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Fab from '@mui/material/Fab'
import SettingsIcon from '@mui/icons-material/Settings'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Link from '@mui/material/Link'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import APITokenImage from '../images/instructions/apiToken.png'
import NewAPITokenImage from '../images/instructions/newApiToken.png'
import TempoApiIntegrationImage from '../images/instructions/tempoApiIntegration.png'
import TempoSettingsImage from '../images/instructions/tempoSettings.png'
import UserIdImage from '../images/instructions/userId.png'
import PeopleSearchImage from '../images/instructions/peopleSearch.png'
import IconButton from '@mui/material/IconButton';
import EventGroupAccordian from '../components/EventGroupAccordian'
import { useLoading } from '../providers/LoadingProvider'
import Badge from '@mui/material/Badge';
import InfoIcon from '@mui/icons-material/Info';
const addDaysToDateString = (dateString, days) => {
  const date = new Date(dateString)
  date.setDate(date.getDate() + days)
  let [d, m, y] = date
    .toLocaleDateString('en-AU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    .split('/')

  return `${y}-${m}-${d}`
}

const eventListMenuOptions = [
  {label: 'Mark as ignore', action: 'IGNORE', icon: DoNotDisturbAltIcon, disableOnIgnore: false},
  {label: 'Add to timesheet', action: 'TIMESHEET', icon: AccessTimeFilledIcon, disableOnIgnore: true}
]
const CalendarPage = () => {
  const classes = useStyles()
  const { isLoading, enableLoading, disableLoading } = useLoading()
  const { displaySuccessToast, displayErrorToast } = useToast()
  const [settings, setSettings] = useState(null)
  const [events, setEvents] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [open, setOpen] = useState(false)
  const [openInstructions, setOpenInstructions] = useState(false)

  const handleMenuOptionClick = async (option, eventGroupIndex, eventIndex) => {
    console.log(`Option ${option} clicked for event ${eventIndex} in event group ${eventGroupIndex}`)
    if(option === 'IGNORE') {
      setEvents((oldState) => {
        const newState = [...oldState]
        newState[eventGroupIndex].events[eventIndex].ignore = !newState[eventGroupIndex].events[eventIndex].ignore
        return newState
      })
    } else if(option === 'TIMESHEET') {
      await pushEventToTempo(eventGroupIndex, eventIndex)
    } else {

    }
  }

  const pushEventToTempo = async (eventGroupIndex, eventIndex, loadingLabel=null) => {
    setEvents((oldState) => {
      const newState = [...oldState]
      newState[eventGroupIndex].events[eventIndex].inProgress = true
      newState[eventGroupIndex].events[eventIndex].complete = false
      return newState
    })
    const event = events[eventGroupIndex].events[eventIndex].event

    enableLoading(loadingLabel ?? `Pushing '${event.title}' to Tempo...`)
    const { error} = await pushEventToTimesheets(event)
    setEvents((oldState) => {
      const newState = [...oldState]
      newState[eventGroupIndex].events[eventIndex].inProgress = false
      newState[eventGroupIndex].events[eventIndex].complete = true
      newState[eventGroupIndex].events[eventIndex].error = error
      return newState
    })
    disableLoading()
  }
  const pushAllEventsToTempo = async () => {
    console.log(`Getting total number of events`)
    const numberOfEvents = calculateTotalNumberOfEvents()
    console.log(`Got total number of events`)

    let currentEventNumber = 1
    for(const [eventGroupIndex, eventGroup] of events.entries()) {
      console.log(`Event group`)
      for(const [eventIndex, event] of eventGroup.events.entries()) {
        console.log(`Event`)
        if(!event.ignore) {

          await pushEventToTempo(eventGroupIndex, eventIndex, `Pushing '${event.event.title}' to Tempo (${currentEventNumber}/${numberOfEvents})...`)
          currentEventNumber++
        }
      }
    }
  }


  useEffect(() => {
    enableLoading(`Getting user settings...`)
    getUserSettings().then((userSettings) => {
      setSettings(userSettings)
      disableLoading()
    })
  }, [disableLoading, enableLoading])

  const getEventsClickHandler = async () => {
    try {
      enableLoading('Getting calendar events...')
      let response = await getCalendarEvents({startDate, endDate})
      const eventGroups = response.events.map((eventGroup) => {
        const {events, ...rest} = eventGroup
        const newEvents = events.map((event) => {
          return {
            event,
            ignore: false,
            error: null,
            inProgress: false,
            complete: false
          }
        })
        return {
          ...rest,
          events: newEvents
        }
      })

      console.log(eventGroups)
      setEvents(eventGroups)
      disableLoading()
    } catch (e) {
      disableLoading()
      displayErrorToast(e.message)
    }
  }

  const openModal = () => setOpen(true)
  const openModalInstructions = () => setOpenInstructions(true)
  const handleClose = () => {
    if(settings) {
      setOpen(false)
    }
  }
  const handleCloseInstructions = () => setOpenInstructions(false)

  const calculateTotalNumberOfEvents = () => {
    return events.reduce((total, eventGroup) => {
      return total + eventGroup.events.filter((e) => !e.ignore).length
    }, 0)
  }
  const handleSettingsUpdate = async (settings) => {
    try {
      enableLoading('Updating user settings...')
      await setUserSettings(settings)
      setSettings(settings)
      setOpen(false)
      disableLoading()
      displaySuccessToast('Successfully updated settings')
    } catch (e) {
      disableLoading()
      displayErrorToast(e.message)
    }
  }

  return (
    <div className={classes.container}>
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
        <h1>Adventure Timesheets - Tempo Timesheet Automation</h1>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={() => openModalInstructions()}
          >
            <InfoIcon/>
          </IconButton>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', marginBottom: 20}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <h3 style={{margin: 0, marginBottom: 5}}>Calendar Events</h3>
          <Paper style={{display: 'flex', padding: 20, justifyContent: 'space-between', width: '100%'}}>
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
              <div style={{display: 'flex'}}>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 50 }}>
                    <InputLabel>Start Date</InputLabel>
                    <Input
                      id='outlined-basic'
                      label='Outlined'
                      variant='outlined'
                      type={'date'}
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div style={{marginRight: 20}}>
                    <InputLabel>End Date</InputLabel>
                    <Input
                      id='outlined-basic'
                      label='Outlined'
                      variant='outlined'
                      type={'date'}
                      value={endDate}
                      disabled={startDate === ''}
                      inputProps={{
                        min: startDate,
                        max: addDaysToDateString(startDate, 7)
                      }}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Button
                  style={{marginRight: 10}}
                  disabled={startDate === '' || endDate === '' || isLoading}
                  variant='contained'
                  onClick={getEventsClickHandler}
                >
                  Get Events
                </Button>
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
              <Badge badgeContent={calculateTotalNumberOfEvents()} color="secondary">
                <Button
                  disabled={isLoading || events.length === 0}
                  variant='contained'
                  onClick={pushAllEventsToTempo}
                >
                  Push All To Tempo
                </Button>
              </Badge>
            </div>
          </Paper>
        </div>
      </div>
      {events.length === 0 ? <Paper style={{display: 'flex', width: '100%', height: 200, justifyContent: 'center', alignItems: 'center'}}><h3>No Events Shown</h3></Paper> : null}
      <EventGroupAccordian eventGroups={events} menuOptions={eventListMenuOptions} onMenuOptionClick={handleMenuOptionClick}/>
      <Fab
        style={{ position: 'absolute', bottom: 20, right: 20 }}
        color='primary'
        aria-label='add'
        onClick={openModal}
      >
        <SettingsIcon />
      </Fab>
      <Modal
        open={open || (!settings && !isLoading)}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 4
          }}
        >
          <UserSettingsForm
            settings={settings}
            handleSettingsUpdate={handleSettingsUpdate}
          />
        </Box>
      </Modal>
      <Modal
        open={openInstructions}
        onClose={handleCloseInstructions}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <HowToUseInstructions />
      </Modal>

    </div>
  )
}

const UserSettingsForm = (props) => {
  const classes = useStyles()
  let { settings, handleSettingsUpdate } = props
  settings = settings ?? {}
  const [jiraId, setJiraId] = React.useState(settings.jiraId ?? '')
  const [apiToken, setApiToken] = React.useState(settings.apiToken ?? '')
  const [open, setOpen] = React.useState(false)
  const openModal = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleSettingsClick = async () => {
    if (handleSettingsUpdate) {
      await handleSettingsUpdate({ jiraId, apiToken })
    }
  }
  return (
    <Paper className={classes.uploadContainer} elevation={6}>
      <h1 className={classes.uploadTitle}>Settings form</h1>
      <br />
      <Stack style={{ width: 500 }}>
        <TextField
          id='outlined-basic'
          label='JIRA User Id'
          variant='outlined'
          value={jiraId}
          onChange={(e) => {
            setJiraId(e.target.value.trim())
          }}
        />
        <br />
        <TextField
          id='outlined-basic'
          label='Tempo API Token'
          variant='outlined'
          value={apiToken}
          onChange={(e) => {
            setApiToken(e.target.value.trim())
          }}
        />
      </Stack>
      <br />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={
            jiraId.length === 0 || apiToken.length === 0 || props.isLoading
          }
          variant='contained'
          onClick={handleSettingsClick}
        >
          Apply settings
        </Button>
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Link style={{ cursor: 'pointer' }} onClick={openModal}>
          First time? Check out these instructions on how to set this tool up.
        </Link>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 4
          }}
        >
          <HowToSetupInstructions />
        </Box>
      </Modal>
    </Paper>
  )
}

const HowToSetupInstructions = () => {
  const classes = useStyles()

  return (
    <Paper
      className={classes.uploadContainer}
      elevation={6}
      style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'scroll' }}
    >
      <h1 className={classes.uploadTitle}>Setup Instructions</h1>
      <br />
      <h2 className={classes.uploadTitle}>Create your Tempo API token</h2>
      <p>
        Login to Jira and click <code>Apps --> Tempo</code>
      </p>
      <p>
        On the left hand side of the screen, click <code>Settings</code>
      </p>
      <img alt={'tempo settings'} src={TempoSettingsImage} height={300} />
      <p>
        Again on the left side of the screen, click <code>API Integration</code>
      </p>
      <img
        alt={'tempo api integration'}
        src={TempoApiIntegrationImage}
        height={300}
      />

      <p>
        Click <code>New Token</code> and give it a name (isn't important what it
        is). Set the expiry to 365 days.
      </p>
      <img alt={'new tempo api token'} src={NewAPITokenImage} height={300} />
      <p>
        Copy the token into the <code>Tempo API Token</code> field.
      </p>
      <img alt={'tempo api token'} src={APITokenImage} height={300} />
      <br />
      <br />
      <h2 className={classes.uploadTitle}>Get your JIRA user ID</h2>
      <p>
        Login to Jira and click <code>People --> Search people and teams</code>
      </p>
      <img alt={'Jira People search'} src={PeopleSearchImage} height={300} />
      <p>Search for yourself and click on your profile</p>
      <p>
        Copy your user ID from up in the URL bar and paste it into the{' '}
        <code>Jira User ID</code> field.
      </p>
      <img alt={'JIRA User Id'} src={UserIdImage} height={300} />
    </Paper>
  )
}

const HowToUseInstructions = () => {
  const classes = useStyles()

  return (
    <Paper
      className={classes.uploadContainer}
      elevation={6}
      style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'scroll' }}
    >
      <h1 className={classes.uploadTitle}>How to name calendar events</h1>
      <br />
      <p>This tool reads in calendar events from your Google Calendar.</p>
      <p>
        Calendar are structured as 3 pipe separated values. Any events not
        following this format will be ignored by the tool. Below is the format
        each event should follow:
      </p>
      <code>Description|Jira Code|Work Type</code>
      <p>
        <code>Work Type</code> can either be the work type name or letter code
        that it uses in tempo. Below is is the list of available work types:
      </p>
      <ul>
        <li>PreSales (A)</li>
        <li>Billable (B)</li>
        <li>ClientNonBillable (C)</li>
        <li>NonBillable (D)</li>
        <li>Leave (H)</li>
        <li>Training (T)</li>
        <li>Recruitment (R)</li>
        <li>Travel (V)</li>
      </ul>
      <h3>Examples</h3>
      <ul>
        <li>
          <code>Standup meeting|DAAMIHNT-1|NonBillable</code>
        </li>
        <li>
          <code>Standup meeting|DAAMIHNT-1|D</code>
        </li>
      </ul>
      <h3>Tips</h3>
      <ul>
        <li>
          If you have any events you can't change the name of, create another
          event over the top of it and name it accordingly.
        </li>
        <li>
          Work types aren't case sensitive so as long as you spell it right, it
          will work.
        </li>
      </ul>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'calc(100vh - 64px)',
      overflowY: 'scroll',
      padding: 20,
      width: '100%'
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    uploadContainer: {
      backgroundColor: 'white',
      color: 'black',
      marginTop: theme.spacing(5),
      padding: theme.spacing(5),
      maxWidth: 700
    },
    uploadTitle: {
      margin: 0,
      color: theme.palette.primary.main
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
})

export default CalendarPage
