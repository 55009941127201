import axios from 'axios'

export async function completeTimesheets(dates) {
  const url = '/api/timesheets/complete'

  return new Promise(async (resolve, reject) => {
    axios
      .post(url, { dates })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}

export const pushEventToTimesheets = async (event) => {
  const url = '/api/timesheets/push'

  return new Promise(async (resolve, reject) => {
    axios
      .post(url, { event })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}
