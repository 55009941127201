import axios from 'axios'

let authStateChangeCallbacks = []
const emptyAuthStateChangeCallbacks = () => {
  authStateChangeCallbacks = []
}

const auth = {
  onAuthStateChanged: (callback) => {
    authStateChangeCallbacks.push(callback)
    getCurrentUser()
    return emptyAuthStateChangeCallbacks
  },
  signOut: async () => {
    await axios.get('/auth/logout')
    await updateCurrentUser()
  }
}

const updateCurrentUser = async (user) => {
  for (let c in authStateChangeCallbacks) {
    await authStateChangeCallbacks[c](user)
  }
}

const getCurrentUser = async () => {
  try {
    let response = await axios.get('/auth/currentUser')
    await updateCurrentUser(response.data.currentUser)
  } catch (e) {
    await updateCurrentUser(null)
  }
}

const signInWithGoogle = async () => {
  await axios.get('/auth/login')
}

export { auth, signInWithGoogle, getCurrentUser }
