import React, { useState } from 'react'
import { useAuth } from './auth/AuthContext'
import ProtectedRoute from './routes/ProtectedRoute'
import UnprotectedRoute from './routes/UnprotectedRoute'
import { Switch } from 'react-router-dom'
import LoginPage from './pages/authPages/LoginPage'
import { CircularProgress } from '@mui/material'

import AuthenticatedLayout from './layouts/AuthenticatedLayout'

import { urls } from './routes/urls'
import { ToastProvider } from './components/ToastContext'
import CalendarPage from './pages/CalendarPage'

const Root = (props) => {
  const { isInitialised } = useAuth()
  return (
    <div>{isInitialised ? <InitialisedLayout /> : <UninitialisedLayout />}</div>
  )
}

const InitialisedLayout = () => {
  const { currentUser } = useAuth()
  const [topBarActions, setTopBarActions] = useState(null)
  return (
    <ToastProvider>
      <div className='App' style={{ backgroundColor: 'rgb(234,235,229)' }}>
        {currentUser != null ? (
          <AuthenticatedLayout actionBar={topBarActions}>
            <AppRouteSwitch setActionBar={setTopBarActions} />
          </AuthenticatedLayout>
        ) : (
          <AppRouteSwitch setActionBar={setTopBarActions} />
        )}
      </div>
    </ToastProvider>
  )
}

const AppRouteSwitch = (props) => {
  return (
    <Switch>
      <UnprotectedRoute
        exact
        path={urls.auth.login.main}
        component={LoginPage}
      />
      <ProtectedRoute
        exact
        path={urls.home.main}
        component={CalendarPage}
        renderTopBarActions={props.setActionBar}
      />
      <ProtectedRoute
        component={() => {
          return <div>Page Not Found</div>
        }}
      />
    </Switch>
  )
}

const UninitialisedLayout = () => {
  return (
    <div
      style={{
        width: 'calc(100vw)',
        height: 'calc(100vh)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(234,235,229)'
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default Root
