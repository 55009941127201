import './App.css'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AuthProvider } from './auth/AuthContext'
import { AnalyticsProvider } from './analytics'
import Root from './Root'
import axios from 'axios'
import { LoadingProvider } from './providers/LoadingProvider'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

// TODO: Add ad group and ad here
const t = createTheme({
  palette: {
    info: {
      main: '#BEBEBE',
      contrastText: '#000'
    }
  }
})

function App() {
  return (
    <Router>
      <AnalyticsProvider>
        <LoadingProvider>
          <AuthProvider>
            <ThemeProvider theme={t}>
              <Root />
            </ThemeProvider>
          </AuthProvider>
        </LoadingProvider>
      </AnalyticsProvider>
    </Router>
  )
}

export default App
