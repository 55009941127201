import React, { createContext, useContext, useMemo, useCallback } from 'react'

const AnalyticsContext = createContext()

export function useAnalytics() {
  return useContext(AnalyticsContext)
}

export function AnalyticsProvider({ children }) {
  /**
   * Sends a page view from the current page.
   */
  const sendPageView = useCallback(() => {}, [])

  /**
   * Adds a custom event to the dataLayer
   * @param eventName - name of the event
   * @param parameters - any extra parameters to add to the event
   */
  const sendEvent = useCallback((eventName, parameters = null) => {
    let event = { event: eventName }
    if (parameters) event.parameters = parameters
    window.dataLayer.push(event)
  }, [])

  /**
   * Adds data to the dataLayer
   * @param data
   */
  const sendDataToDataLayer = useCallback((data) => {
    window.dataLayer.push(data)
  }, [])

  /**
   * Returns the UNIX timestamp value for the current time.
   * @returns {number}
   */
  const getCurrentTimestamp = useCallback(() => {
    return Math.floor(Date.now() / 1000)
  }, [])

  const analyticsFunctions = useMemo(
    () => ({
      sendPageView,
      sendEvent,
      sendDataToDataLayer,
      getCurrentTimestamp
    }),
    [sendPageView, sendEvent, sendDataToDataLayer, getCurrentTimestamp]
  )

  return (
    <AnalyticsContext.Provider
      value={{
        ...analyticsFunctions
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
