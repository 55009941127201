import axios from 'axios'

export async function getUserSettings() {
  const url = '/api/settings/get'

  return new Promise(async (resolve, reject) => {
    axios
      .get(url)
      .then((result) => {
        resolve(result.data.settings)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}

export async function setUserSettings(settings) {
  const url = '/api/settings/create'

  return new Promise(async (resolve, reject) => {
    axios
      .post(url, { settings })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}
