import { useAuth } from '../auth/AuthContext'
import { Redirect, Route } from 'react-router-dom'
import { appRequiresVerification } from './routeUtils'

export default function UnprotectedRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser == null) {
          return <Component {...props} />
        } else {
          let params = new URLSearchParams(props.location.search)
          let redirectUrl = params.get('redirect_url') ?? '/'
          let redirectUrlSplit = redirectUrl.split('?')
          let pathname = redirectUrlSplit[0]
          let search = redirectUrlSplit.length > 0 ? redirectUrlSplit[1] : null

          if (!appRequiresVerification() || currentUser.emailVerified) {
            return (
              <Redirect
                to={{
                  pathname: pathname,
                  search: search,
                  state: { from: props.location }
                }}
              />
            )
          } else {
            return (
              <Redirect
                to={{
                  pathname: pathname,
                  search: search,
                  state: { from: props.location }
                }}
              />
            )
          }
        }
      }}
    />
  )
}
