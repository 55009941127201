import { useAuth } from '../auth/AuthContext'
import { Redirect, Route } from 'react-router-dom'
import { appRequiresVerification } from './routeUtils'

export default function ProtectedRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser != null) {
          if (appRequiresVerification() && !currentUser.emailVerified) {
            return (
              <Redirect
                to={{ pathname: '/validate', state: { from: props.location } }}
              />
            )
          }
          return <Component {...props} {...rest} />
        } else {
          let redirectUrl = `${props.location.pathname ?? '/'}${
            props.location.search ?? ''
          }`
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: props.location.pathname
                  ? `redirect_url=${encodeURIComponent(redirectUrl)}`
                  : null,
                state: { from: props.location }
              }}
            />
          )
        }
      }}
    />
  )
}
