export const urls = {
  auth: {
    login: {
      main: '/login'
    }
  },
  trafficking: {
    main: '/trafficking'
  },
  home: {
    main: '/'
  },
  calendar: {
    main: '/calendar'
  }
}
