import React from 'react'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import { useLocation } from 'react-router-dom'
import logo from '../../images/adventuretime.jpg'
export default function LoginPage() {
  const classes = useStyles()
  const { search } = useLocation()
  return (
    <div className={classes.root}>
      {/*<CssBaseline />*/}
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Paper
          component={Paper}
          elevation={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '500px',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              padding: 20,
              width: '100%'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img style={{ height: 300 }} alt={'company logo'} src={logo} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h1>Adventure Timesheets</h1>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            >
              <div>
                <p style={{ textAlign: 'center' }}>
                  Automated Timesheet Completion Tool
                </p>
              </div>
              <Button
                href={`/auth/login${search ?? ''}`}
                style={{ marginTop: 30 }}
                variant={'contained'}
                color={'primary'}
              >
                Login With Google
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw'
  }
}))
