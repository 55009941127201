import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo
} from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const ToastContext = createContext()

export function useToast() {
  return useContext(ToastContext)
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export function ToastProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')
  const [autoHideDuration, setAutoHideDuration] = useState(5000)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setIsOpen(false)
  }

  const displaySuccessToast = useCallback((message, duration = 5000) => {
    setMessage(message)
    setSeverity('success')
    setAutoHideDuration(duration)
    setIsOpen(true)
  }, [])

  const displayErrorToast = useCallback((message, duration = 5000) => {
    setMessage(message)
    setSeverity('error')
    setAutoHideDuration(duration)
    setIsOpen(true)
  }, [])

  // Needed to prevent re-renders occurring when the toast state changes
  const displayFunctions = useMemo(
    () => ({ displaySuccessToast, displayErrorToast }),
    [displaySuccessToast, displayErrorToast]
  )

  return (
    <ToastContext.Provider value={displayFunctions}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: '100%', whiteSpace: 'pre-wrap' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  )
}
