import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'

const LoadingContext = createContext()

export function useLoading() {
  return useContext(LoadingContext)
}

export function LoadingProvider({ children }) {
  let [isLoading, setIsLoading] = useState(false)
  const [label, setLabel] = useState(null)
  const enableLoading = useCallback(async (label=null) => {
    setIsLoading(true)
    setLabel(label)
  }, [])

  const disableLoading = useCallback(async () => {
    setIsLoading(false)
    setLabel(null)
  }, [])

  const loadingFunctions = useMemo(
    () => ({
      enableLoading,
      disableLoading
    }),
    [enableLoading, disableLoading]
  )

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        ...loadingFunctions
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => 1000000}}
        open={isLoading}
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          {label ? <h2>{label}</h2>: null}
          <CircularProgress color='inherit' />
        </div>
      </Backdrop>
      {children}
    </LoadingContext.Provider>
  )
}
