import React, {useState} from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventListItem from './EventListItem'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
const EventGroupAccordion = ({eventGroups, menuOptions=[], onMenuOptionClick}) => {
  const classes = useStyles()
  const [eventGroupAccordianIsOpen, setEventGroupAccordianIsOpen] = useState(eventGroups.map(() => true))
  console.log(eventGroupAccordianIsOpen)
  const toggleAccordion = (index) => {
    setEventGroupAccordianIsOpen((oldState) => {
      const newState = [...oldState]
      newState[index] = !newState[index]
      return newState
    })
  }

  return (
    <div className={classes.container}>
      {eventGroups.map((eventGroup, groupIndex) => {

        return(<Accordion key={`event-group-${groupIndex}`} defaultExpanded={true} style={{width: '100%'}} onChange={() => toggleAccordion(groupIndex)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`event-group-${groupIndex}`}
              id={`event-group-${groupIndex}`}
            >
              <h3 style={{margin: 0}}>{moment(eventGroup.date).format('dddd MMMM Do YYYY')}</h3>
            </AccordionSummary>
            <AccordionDetails>
              {eventGroup.events.map((event, eventIndex) =><EventListItem {...event} key={`event-${eventIndex}`} menuOptions={menuOptions} onMenuOptionClick={async (option) => {
                await onMenuOptionClick(option, groupIndex, eventIndex)
              }}/>)}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    }
  }
})

export default EventGroupAccordion