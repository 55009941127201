import React, { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline/CssBaseline'
import MuiAppBar from '@mui/material/AppBar/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Box, Drawer } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import { useAuth } from '../auth/AuthContext'
import { styled, useTheme } from '@mui/material/styles'

import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Divider from '@mui/material/Divider'
import ListSubheader from '@mui/material/ListSubheader'
import { urls } from '../routes/urls'
import { NavLink } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LogoutIcon from '@mui/icons-material/Logout'
import logo from '../images/mm.png'
const drawerWidth = 300

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,

      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

function ListItemLink(props) {
  const { icon, primary, to, exact, onClick } = props
  const classes = useStyles()

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <NavLink
          activeClassName={classes.activeLink}
          ref={ref}
          to={to}
          exact={exact}
          {...linkProps}
        />
      )),
    [to, exact, classes.activeLink]
  )

  return (
    <li>
      <ListItem onClick={onClick} button component={CustomLink}>
        <ListItemIcon style={{ color: 'inherit' }}>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  )
}

const AuthenticatedLayout = (props) => {
  const classes = useStyles()
  const { currentUser, logout } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleLogout = (e) => {
    handleClose(e)
    logout()
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' open={open} className={classes.appBar}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box display='flex' flexGrow={1}>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
              {props.actionBar}
            </IconButton>
            <NavLink to={urls.home.main} style={{ display: 'flex' }}>
              <img style={{ height: 64 }} alt={'company logo'} src={logo} />
            </NavLink>
          </Box>
          <div className={classes.profileContainer} onClick={handleMenu}>
            <Avatar
              style={{ marginRight: 10 }}
              variant='rounded'
              onClick={handleMenu}
            >
              <img
                alt={'user'}
                height='40px'
                referrerPolicy='no-referrer'
                src={currentUser.picture}
              />
            </Avatar>
            <Typography
              component={'p'}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                margin: 0,
                textAlign: 'left'
              }}
            >
              <small>{currentUser.name}</small>
            </Typography>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>
                <LogoutIcon style={{ marginRight: 8 }} />
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {linkSections.map((section, sectionIndex) => {
          return (
            <div key={`menu-list-section-${sectionIndex}`}>
              <Divider />
              <List>
                <ListSubheader>{section.title}</ListSubheader>
                {section.links.map((link, linkIndex) => (
                  <ListItemLink
                    onClick={() => setOpen(false)}
                    key={`${link.title}-${linkIndex}`}
                    {...link}
                    exact={link.exact}
                  />
                ))}
              </List>
            </div>
          )
        })}
      </Drawer>
      <Main className={classes.content} open={open}>
        <div className={classes.toolbar} />
        {props.children}
      </Main>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex'
    },
    appBar: {
      backgroundColor: 'white', //theme.palette.primary.main
      color: 'black'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.main,
      padding: 0
    },
    activeLink: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      },
      color: 'black'
    },
    profileContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    }
  }
})

const linkSections = [
  {
    title: null,
    links: [
      // { primary: 'History', to: '/#', icon: <HistoryIcon />, exact: true }
    ]
  }
]

export default AuthenticatedLayout
