import axios from 'axios'

export const getCalendarEvents = async (dates) => {
  const url = '/api/calendar/events'
  const { startDate, endDate } = dates
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${url}?startDate=${startDate}&endDate=${endDate}`)
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}
