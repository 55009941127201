import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth, signInWithGoogle } from './auth'
// Add the Firebase services that you want to use
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import axios from 'axios'
import { useAnalytics } from '../analytics'
import { createHash } from 'crypto'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [isInitialised, setIsInitialised] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')
  const { sendDataToDataLayer } = useAnalytics()

  function displayAuthError(message) {
    setMessage(message)
    setSeverity('error')
    setIsOpen(true)
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setIsOpen(false)
  }

  function signup(email, password) {
    return //auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return //auth.signInWithEmailAndPassword(email, password);
  }

  function loginWithGoogle() {
    return new Promise(async (resolve, reject) => {
      try {
        await signInWithGoogle()
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  async function logout() {
    return auth.signOut()
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user)
      let userId = null
      if (user) {
        let hash = createHash('sha256')
        hash.update(user.email)
        userId = hash.digest('hex')
      }
      sendDataToDataLayer({ user_id: userId })
      setIsInitialised(true)
    })
    axios.interceptors.response.use(
      (config) => {
        return config
      },
      async (error) => {
        if (error.response && error.response.status === 401) {
          await logout()
        }
        return Promise.reject(error)
      }
    )
    return unsubscribe
  }, [sendDataToDataLayer])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isInitialised,
        displayAuthError,
        signup,
        login,
        loginWithGoogle,
        logout
      }}
    >
      {children}
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  )
}
