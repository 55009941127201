import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

const LogType = {
  BILLABLE: 'Billable',
  NONBILLABLE: 'NonBillable',
  CLIENTNONBILLABLE: 'ClientNonBillable',
  PRESALES: 'Presales',
  LEAVE: 'Leave',
  TRAINING: 'Training',
  RECRUITMENT: 'Recruitment',
  TRAVEL: 'Travel',
  A: 'PreSales',
  B: 'Billable',
  C: 'ClientNonBillable',
  D: 'NonBillable',
  H: 'Leave',
  T: 'Training',
  R: 'Recruitment',
  V: 'Travel'
}

const iconColor = ({ disableOnIgnore, ignore }) => {
  if (!disableOnIgnore && ignore) {
    return 'white'
  } else if(disableOnIgnore && ignore) {
    return 'darkgrey'
  }
  else {
    return 'grey'
  }
}

const StatusChipLabel = ({ inProgress, complete, error }) => {
  if (inProgress) {
    return 'In Progress'
  } else if (error) {
    return 'Error'
  } else if (complete) {
    return 'Complete'
  } else {
    return 'Not Started'
  }
}

const StatusChipColor = ({ inProgress, complete, error }) => {
  if (inProgress) {
    return 'primary'
  } else if (error) {
    return 'error'
  } else if (complete) {
    return 'success'
  } else {
    return 'info'
  }
}
const EventListItem = ({ event, inProgress, complete, error, ignore, menuOptions, onMenuOptionClick }) => {
  const startDateTime = moment(event.startDateTime);
  const endDateTime = moment(event.endDateTime);
  const [modalOpen, setModalOpen] = useState(false)
  let duration = moment.duration(endDateTime.diff(startDateTime)).asHours();
  let durationType = 'hours';
  if(duration < 1) {
    duration = moment.duration(endDateTime.diff(startDateTime)).asMinutes()
    durationType = 'minutes'
  }
  return (
    <Paper elevation={3} style={{width: '100%', display: 'flex', borderRadius: 10, marginBottom: 10, overflow: 'hidden', minHeight: 150, backgroundColor: ignore ? 'grey' : 'white'}}>
      <div style={{width: '35%', backgroundColor: 'purple', color: 'white', padding: 20}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
            <p style={{margin: 0, display: 'flex', alignItems: 'center'}}>{event.timesheetCode}</p>
            <Chip style={{margin: 0}} label={LogType[event.billingCode.toUpperCase()]} color={'primary'}/>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <h2 style={{margin: 0}}>{event.title}</h2>
            <h3 style={{margin: 0, textAlign: 'end'}}>{duration} {durationType}</h3>
          </div>
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 20, width:'100%'}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <small><strong>DETAILS</strong></small>
            </div>
            <div style={{display: 'flex'}}>
              {menuOptions.map((option, optionIndex) => (
                <Tooltip key={`menu-option-${optionIndex}-${event.id}`} title={option.label}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => onMenuOptionClick(option.action)}
                    disabled={option.disableOnIgnore && ignore}
                  >
                    <option.icon style={{color: iconColor({disableOnIgnore: option.disableOnIgnore, ignore})}} />
                  </IconButton>
                </Tooltip>
              ))}
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <small><strong>Start</strong></small>
            <p style={{margin: 0, marginLeft: 10, marginBottom: 10}}>{moment(event.startDateTime).format('dddd MMMM Do YYYY, h:mm:ss a')}</p>
            <small><strong>End</strong></small>
            <p style={{margin: 0, marginLeft: 10}}>{moment(event.endDateTime).format('dddd MMMM Do YYYY, h:mm:ss a')}</p>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{margin: 0, marginRight: 10}}><small><strong>STATUS</strong></small></p>
            {error ? <Badge onClick={() => setModalOpen(true)} style={{cursor: 'pointer'}} badgeContent={1} color="secondary">
              <Chip variant={'outlined'} style={{margin: 0, cursor: 'pointer'}} label={StatusChipLabel({inProgress, complete, error})} color={StatusChipColor({inProgress, complete, error})}/>
            </Badge> : <Chip variant={'outlined'} style={{margin: 0}} label={StatusChipLabel({inProgress, complete, error})} color={StatusChipColor({inProgress, complete, error})}/> }
            </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 4
          }}
        >
          <Paper style={{padding: 20, display: 'flex', flexDirection: 'column'}}>
            <h2 style={{margin:0, marginBottom: 20}} >Tempo Error Message</h2>
            <p style={{margin:0}}>{error}</p>
          </Paper>

        </Box>
      </Modal>
    </Paper>
  )
}

export default EventListItem